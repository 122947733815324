<template>
  <div class="w-full flex justify-end items-end mt-8">
    <SelectBox :options="users" @changeUser="handleUser"></SelectBox>
  </div>
</template>

<script setup>
import SelectBox from "@/components/selectbox/SelectBox.vue";

defineProps(["users"]);

const emit = defineEmits(["changeUser"]);

function handleUser(user) {
  emit("changeUser", user);
}
</script>
